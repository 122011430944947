import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { ArticleCard } from '../../components/article_card/article_card';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { SEO } from '../../components/seo';
import { GatsbyPageProps } from '../../gatsby-types';
import Layout from '../../layouts/layout';
import { PrismicArticle } from '../../models/prismic_article';
import { PrismicSeries } from '../../pages/series';
import * as styles from './series.module.less';

export const query = graphql`
  query($uid: String, $tag_name: String) {
    prismicSeries(uid: { eq: $uid }) {
      ...PrismicSeriesFragment
    }
    allPrismicArticle(
      filter: { tags: { eq: $tag_name } }
      sort: { fields: data___publication_date, order: DESC }
    ) {
      nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

const SeriesPage = (props: GatsbyPageProps) => {
  const series = props.data.prismicSeries as PrismicSeries;
  if (!series) {
    console.log('Series not found');
    return null;
  }

  const { name } = series.data;
  const articles = props.data.allPrismicArticle.nodes as PrismicArticle[];

  return (
    <Layout>
      <SEO title={RichText.asText(name.raw)} />
      <ContentWrapper>
        <article>
          <h1>{RichText.asText(name.raw)} Series</h1>

          <h2>Articles</h2>
          <ol className={styles.articles}>
            {articles.map((article) => (
              <li className={styles.article} key={article.uid}>
                <ArticleCard article={article} />
              </li>
            ))}
          </ol>
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default SeriesPage;
